class Cookies {
  static set(name, value, ttl) {
    if (!ttl) {
      ttl = 60 * 60 * 24 * 365 * 1000;
    }

    let sitewide = ';path=/';
    // let cookieDomain = ';domain=' + window.settings.cookie_domain;

    let date = new Date();
    date.setTime(date.getTime() + ttl);
    let cookieExpire = "; expires=" + date.toUTCString();

    // document.cookie = name + '=' + value + cookieExpire + cookieDomain + sitewide;
    document.cookie = name + '=' + value + cookieExpire + sitewide;
  }

  static get(name) {
    let cookies = this.parseCookies();
    return cookies[name];
  }

  static disabled(name) {
    let value = this.get(name);
    return value === "true";
  }

  static parseCookies() {
    let cookies = document.cookie.split('; ');

    let ret = {};
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].split('=');
      ret[cookie[0]] = cookie[1];
    }
    return ret;
  }
}

export default Cookies;