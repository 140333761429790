class Tracking {
  constructor() {
    this.onUrlChange = this.onUrlChange.bind(this);
    this.pageview = this.pageview.bind(this);
    this.initialize = this.initialize.bind(this);

    window.addEventListener('load', this.initialize, { once: true });

    this._debug = settings.env !== "production";
  }

  initialize() {
    window.addEventListener('urlChange', this.onUrlChange);
    window.addEventListener('trackEvent', this.onEvent);
  }

  onUrlChange(e) {
    this.pageview({
      page: e.detail.pathname
    })
  }

  pageview(args={}) {
    /*if (_sp.cookies.get("cookieconsent_status") !== "allow") {
      return false;
    }*/

    if (this._debug) {
      // _kb.debug("[GA]", "Pageview", (args.page || location.pathname));
      return;
    }

    if (!ga) {
      return;
    }

    ga('send', {
      hitType: 'pageview',
      ...args
    });
  }

  onEvent(e) {
    if (_sp.cookies.get("cookieconsent_status") !== "allow") {
      return;
    }

    if (!ga) {
      return;
    }

    ga('send', 'event', e.detail)
  }
}

export default Tracking