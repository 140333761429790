class Dom {
  static select(selector) {
    return Array.from(document.querySelectorAll(selector));
  }

  static addEvents(type, selector, callback) {
    this.select(selector).forEach((elem) => {
      elem.addEventListener(type, callback)
    });
  }

  static removeEvents(type, selector, callback) {
    this.select(selector).forEach((elem) => {
      elem.removeEventListener(type, callback)
    });
  }

  static addEvent(type, selector, callback) {
    let elem = document.querySelector(selector);
    if (elem) {
      elem.addEventListener(type, callback);
    }
  }

  static removeClass(selector, klass) {
    this.select(selector).forEach((elem) => {
      elem.classList.remove(klass);
    })
  }

  static addClass(selector, klass) {
    this.select(selector).forEach((elem) => {
      elem.classList.add(klass);
    })
  }

  static toggleClass(selector, klass) {
    this.select(selector).forEach((elem) => {
      elem.classList.toggle(klass);
    })
  }

  static addOverlay(content) {
    let div = document.createElement('div');
    div.innerHTML = content;
    document.body.appendChild(div.firstChild);

    _sh.dom.addEvent("click", "#generic-overlay .background", function() {
      document.getElementById("generic-overlay").remove();
    });
    _sh.fixCheckboxes();
  }

  static inViewport(elem, offset=0) {
    if (!elem) {
      return false;
    }

    let rect = elem.getBoundingClientRect();
    return (
      rect.top >= -rect.height / 2 &&
      rect.bottom - offset <= (window.innerHeight || document.documentElement.clientHeight) + rect.height / 2 &&
      rect.left >= -rect.width / 2 && rect.right <= (window.innerWidth || document.documentElement.clientWidth) + rect.width / 2
    );
  }

  static offset(el) {
    let _x = 0;
    let _y = 0;
    while (el && !isNaN( el.offsetLeft ) && !isNaN(el.offsetTop)) {
      _x += el.offsetLeft - el.scrollLeft;
      _y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }

    return { top: _y, left: _x };
  }

  static getParameterByName(name) {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'), results = regex.exec(url);

    if (!results) {
      return null;
    }

    if (!results[2]) {
      return '';
    }

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  static getAllParameters() {
    let queryDict = {};
    location.search.substr(1).split("&").forEach((item) => {
      let split = item.split("=");
      queryDict[split[0]] = split[1];
    });
    return queryDict;
  }

  static buildQueryString(params) {
    return Object.keys(params).map(key => {
      return key + "=" + (params[key] || "");
    }).join("&");
  }

  static updateQueryStringParameter(uri, key, value) {
    let re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    let separator = uri.indexOf('?') !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, '$1' + key + "=" + value + '$2');
    }

    return uri + separator + key + "=" + value;
  }
}

export default Dom