class Gdpr {
  constructor() {
    this.onLoadCallback = this.onLoadCallback.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);

    window.addEventListener("load", this.onLoadCallback);
  }

  onLoadCallback() {
    this.injectScripts();

    _sp.dom.addEvent('click', '#cookie-toggle', () => {
      this.showCookieNotice(true);
    });

    if (_sp.cookies.get("cookieconsent_status") === "allow") {
      return;
    }

    this.showCookieNotice();
  }

  onStatusChange(status) {
    if (status === "allow") {
      this.injectScripts();
    } else {
      this.removeScripts();
    }

    document.querySelector('.cc-revoke').remove();
  }

  showCookieNotice(force=false) {
    if (force) {
      _sp.cookies.set("cookieconsent_status", "", -100000);
    }

    if (!window.cookieconsent) {
      return;
    }

    window.cookieconsent.initialise({
      palette: {
        popup: {
          "background": "#aa0000",
          "text": "#ffdddd"
        },
        "button": {
          "background": "#ff0000"
        }
      },
      cookie: {
        domain: window.settings.cookie_domain
      },
      theme: "edgeless",
      position: "bottom-right",
      type: "opt-in",
      content: {
        "href": "/privacy"
      },
      revokable: false,
      onStatusChange: this.onStatusChange,
      law: {
        regionalLaw: false
      },
      location: true,
      enabled: true
    });
  }

  injectScripts() {
    if (_sp.cookies.get("cookieconsent_status") !== "allow") {
      return false;
    }

    _sp.dom.select('[cookie-type="gdpr"]').forEach(element => {
      let script = document.createElement("script");
      script.setAttribute("type", "text/javascript");
      script.setAttribute('cookie-type', element.getAttribute('cookie-type'));

      if (element.getAttribute("src")) {
        script.setAttribute("src", element.getAttribute("src"));
      } else {
        script.text = element.innerHTML;
      }

      document.head.appendChild(script);
      element.parentNode.removeChild(element);
    });

    window.dispatchEvent(new Event('scriptsInjected'));

    return true;
  }

  removeScripts() {
    _sp.dom.select('[cookie-type="gdpr"]').forEach(script => {
      script.parentNode.removeChild(script);
    })
  }
}

export default Gdpr