class ScrollTop {
  constructor() {
    this.initialize = this.initialize.bind(this);
    this.scrollCallback = this.scrollCallback.bind(this);

    document.addEventListener('DOMContentLoaded', this.initialize);
  }

  initialize() {
    this._element = document.querySelector("#scroll-top");

    window.addEventListener("scroll", this.scrollCallback);
    this._element.addEventListener("click", this.clickCallback);
  }

  scrollCallback() {
    let height = document.body.scrollHeight * 0.10;
    let top = window.scrollY;

    if (top > height) {
      if (this._element.classList.contains(".visible")) {
        return;
      }

      this._element.classList.add("visible");
    }
    else {
      this._element.classList.remove("visible")
    }
  }

  clickCallback(e) {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    });
  }
}

export default ScrollTop