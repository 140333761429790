class Storage {
  constructor() {
    this.enabled = typeof(window) === "object" && typeof(document) === "object";
  }

  set(key, value, store="local") {
    if (!this.enabled) {
      return false;
    }

    switch (store) {
      case "local":
        if (typeof(localStorage) !== "object") {
          return;
        }

        localStorage[key] = JSON.stringify(value);
        break;
      case "session":
        if (typeof(sessionStorage) !== "object") {
          return;
        }

        sessionStorage[key] = JSON.stringify(value);
        break;
      case "memory":
        window[key] = value;
        break;
    }
    return true;
  }

  get(key, store="local") {
    if (!this.enabled) {
      return false;
    }

    let value = undefined;
    switch (store) {
      case "local":
        if (typeof(localStorage) !== "object") {
          return;
        }

        value = localStorage[key];
        if (!value || value === "undefined") {
          return;
        }
        return JSON.parse(value);
      case "session":
        if (typeof(sessionStorage) !== "object") {
          return;
        }

        value = sessionStorage[key];
        if (!value || value === "undefined") {
          return;
        }
        return JSON.parse(value);
      case "memory":
        return window[key];
    }
  }
}

export default Storage