import "@babel/polyfill";

import axios from 'axios/index'

import Dom from '../classes/dom';
import Cookies from '../classes/cookies';
import ScrollTop from '../classes/scroll_top';
import Storage from '../classes/storage';
import Tracking from "../classes/tracking";
import Gdpr from "../classes/gdpr";
import User from "../classes/user";

import 'lazysizes';
/*import 'video.js'
import 'javascripts/videojs/videojs.persistvolume.js';
import 'javascripts/videojs/vast/videojs_5.vast.vpaid.js';*/
import 'hammerjs';

import '../classes/frontend_loader';

class SimplyPorn {
  constructor() {
    this.onLoad = this.onLoad.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
    this.onExoZoneAdded = this.onExoZoneAdded.bind(this);

    this.cookies = Cookies;
    this.dom = Dom;
    this.scrollTop = new ScrollTop();
    this.tracking = new Tracking();
    this.gdpr = new Gdpr();
    this.storage = new Storage();
    this.user = new User();

    this.axios = this.generateAxiosClient();

    window.lazySizesConfig = window.lazySizesConfig || {};
    window.lazySizesConfig.expand = 10;
    window.lazySizesConfig.expFactor = 1.5;

    this.exoZones = [];

    this.bindEvents();
  }

  bindEvents() {
    document.addEventListener('DOMContentLoaded', this.onLoad);
    document.addEventListener('lazybeforeunveil', function(e){
      let bg = e.target.dataset.bg;
      if (bg){
        e.target.style.backgroundImage = 'url("' + bg + '")';
      }
    });
    window.addEventListener('urlChange', this.onNavigate);
    window.addEventListener('exoLoaderZoneAdded', this.onExoZoneAdded);
  }

  onLoad() {
    if (navigator.serviceWorker && window.settings.env !== "development") {
      navigator.serviceWorker.register("/serviceworker.js");
    }
    document.addEventListener('click', this.onClick);
  }

  onNavigate(e) {
    if (e.detail.pathname === "/images" || e.detail.pathname.includes('/image/') || e.detail.pathname.includes('/gif/')) {
      return;
    }

    this.storage.set('image-tag', undefined, "session");
    this.storage.set('image-sort', undefined, "session");
  }

  onClick(e) {
    if (e.target.closest(".scroll-top") || e.target.closest(".sidebar")) {
      window.scrollTo(0,0);
    }
  }

  onExoZoneAdded() {
    if (this._exoTimeout) {
      clearTimeout(this._exoTimeout);
    }

    this.serveExoAds();
  }

  serveExoAds() {
    let loader = this.exoLoader();

    Dom.select("script[src*='util.simply-hentai.com/prod']").forEach((elem => {
      elem.remove();
    }));

    for (let i = 0; i < this.exoZones.length; i++) {
      let data = this.exoZones[i];
      let elem = document.getElementById(data['codeId']);
      if (!elem) {
        continue;
      }

      elem.innerHTML = '';

      let div = document.createElement('div');
      elem.appendChild(div);
      data.container = div;

      if (loader) {
        loader.addZone(data);
      }
    }

    if (loader) {
      loader.serve({ "script_url": "https://util.simply-hentai.com/prod/" + new Date().getTime(), "force": true });
    }

    this.exoZones = [];
  }

  exoLoader() {
    let myEl = { l: null}, event = new CustomEvent('retrieveexoloader', {"detail": myEl});
    window.document.dispatchEvent(event);
    return myEl.l;
  }

  addExoZone(data) {
    this.exoZones.push(data);
    window.dispatchEvent(new Event('exoLoaderZoneAdded'));
  }

  visit(url) {
    let event = new CustomEvent('visitUrl', { detail: { url: url }});
    window.dispatchEvent(event);
    if (event.detail.handled) {
      window.scrollTo(0, 0);
    } else {
      window.location.href = url;
    }
  }

  changeMeta(objects) {
    if (!objects) {
      return;
    }

    let meta = objects.meta_tags ? objects.meta_tags : objects;

    window.dispatchEvent(new CustomEvent('changeMeta', { detail: { meta: meta }}));
  }

  generateAxiosClient(options={}) {
    axios.defaults.params = {};
    axios.defaults.params['token'] = settings.api_token;

    let headers = {
      common: {
        "X-Requested-With": "XMLHttpRequest"
      }
    };

    if (this.user.isLoggedIn()) {
      headers.Authorization = this.user.token.replace("Bearer+", "Bearer ");
    }

    let client = axios.create({
      withCredentials: true,
      headers: headers
    });

    if (options.regenerate) {
      this.axios = client;
    }

    return client;
  }

  random() {
    return Math.round(Math.random() * 10000);
  }

  inject(url, type, opts={}) {
    let callback = opts.callback;
    let uniqueCallback = opts.uniqueCallback;
    let id = opts.id;
    let async = opts.async || false;
    let unique = opts.unique || false;
    let exist = id && document.getElementById(id);
    let node = opts.node;
    let data = opts.data;
    let append = opts.append || false;

    if (unique && exist) {
      if (uniqueCallback) {
        uniqueCallback();
      }

      return;
    }

    if (exist) {
      document.getElementById(id).remove();
    }

    switch (type) {
      case "js":
        let d = document, g=d.createElement('script');
        let s = node || d.getElementsByTagName('script')[0];
        if (append) {
          s = document.scripts[document.scripts.length - 1];
        }

        g.type='text/javascript';

        if (data) {
          for (let key of Object.keys(data)) {
            g.dataset[key] = data[key];
          }
        }

        g.src=url;

        if (id) {
          g.id = id;
        }

        if (typeof(callback) === 'function') {
          g.onload = callback;
        }

        if (async) {
          g.async=true;
          g.defer=true;
        }

        s.parentNode.insertBefore(g,s);
        break;
      case "css":
        let css=document.createElement('link'); css.rel = 'stylesheet'; css.href= url;

        if (id) {
          css.id = id;
        }

        document.head.appendChild(css);
        break;
    }
  }
}

window._sp = (window._sp || new SimplyPorn());