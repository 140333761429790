import { Base64 } from 'js-base64';
import Cookies from './cookies';

class User {

  constructor() {
    this.afterApiCallback = this.afterApiCallback.bind(this);

    this.token = undefined;

    this.loadData();

    window.addEventListener("DOMContentLoaded", () => {
      if (this.isLoggedIn()) {
        this.details()
          .catch(() => {
            this.cleanUser()
          })
      }
    });
  }

  isLoggedIn() {
    if (!this.token) {
      return false;
    }

    let payload = this.token.split(".")[1];
    if (!payload) {
      return false;
    }

    let data = Base64.fromBase64(payload);
    if (!data) {
      return false;
    }

    data = JSON.parse(data);
    let exp = data['exp'] * 1000;

    return new Date().getTime() < exp;
  }

  ping() {
    return _sp.axios.get(settings.api_base + "/" + settings.api_version + "/user/ping");
  }

  details() {
    return _sp.axios.get(settings.api_base + "/" + settings.api_version + "/user/details")
      .then((res) => {
        this.saveData();
        window.dispatchEvent(new Event('userDetailsLoaded'));
      });
  }

  signUp(username, email, password) {
    return _sp.axios.post(settings.api_base + "/" + settings.api_version + "/user", {
      user: {
        username: username,
        email: email,
        password: password
      }
    }).then(this.afterApiCallback);
  }

  signIn(email, password) {
    return _sp.axios.post(settings.api_base + "/" + settings.api_version + "/user/sign_in", {
      user: {
        email: email,
        password: password
      }
    }).then(this.afterApiCallback);
  }

  signOut() {
    return _sp.axios.delete(settings.api_base + "/" + settings.api_version + "/user/sign_out")
      .then(() => {
          this.cleanUser();
        }
      );
  }

  requestPassword(email) {
    return _sp.axios.post(settings.api_base + "/" + settings.api_version + "/user/password", {
      user: {
        email: email
      }
    });
  }

  followTag(name) {
    return _sp.axios.post(settings.api_base + "/" + settings.api_version + "/tag/follow/" + name).then(() => {
      this.loadFollowingData();
    });
  }

  afterApiCallback(res) {
    this.token = res.headers.authorization;

    if (this.isLoggedIn()) {
      _sp.generateAxiosClient({ regenerate: true });
      this.details();
      window.dispatchEvent(new Event('userStateChanged'));
    }
  }

  loadData() {
    if (Cookies.get("token")) {
      this.token = Cookies.get("token");
    }
  }

  saveData() {
    if (this.token) {
      return Cookies.set("token", this.token);
    }

    Cookies.set("token", this.token, -1000);
  }

  cleanUser() {
    this.token = undefined;
    this.saveData();
    _sp.generateAxiosClient({ regenerate: true });
    window.dispatchEvent(new Event('userStateChanged'));
  }
}

export default User;